.NavLink-module__NavLink___3i38c {
  position: relative;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #FFFFFF; }
  .NavLink-module__NavLink___3i38c:after {
    display: block;
    content: '';
    width: 0;
    margin-top: 4px;
    border-bottom: 1px solid transparent;
    transition-duration: 0.3s;
    opacity: 0; }

.NavLink-module__NavLink___3i38c:hover:after {
  width: 100%;
  border-color: #05A4EF;
  opacity: 1; }

.NavLink-module__Active___2nv98:after {
  width: 100%;
  border-color: #05A4EF;
  opacity: 1; }
