.ColumnWithLink-module__Container___3XIXP {
  position: relative;
  display: flex;
  flex-direction: column; }

.ColumnWithLink-module__HeaderContainer___usJ1l {
  display: block;
  overflow: hidden;
  color: #FFFFFF;
  padding-top: 20px; }

.ColumnWithLink-module__HeaderTop___3UG5o {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  margin-top: 30px; }

.ColumnWithLink-module__HeaderBottom___tIDgV {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #808080;
  margin-top: 30px; }

.ColumnWithLink-module__Image___2hjeg {
  height: 70px;
  margin-top: 20px; }

.ColumnWithLink-module__Text___2qcxG {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  margin-top: 30px;
  color: #FFFFFF;
  opacity: 0.9;
  margin-bottom: 100px; }

.ColumnWithLink-module__ButtonLink___1hO_c {
  display: inline-block; }

.ColumnWithLink-module__Button___Yae_1 {
  position: absolute;
  bottom: 0;
  width: 100%; }
  @media (min-width: 500px) {
    .ColumnWithLink-module__Button___Yae_1 {
      width: auto; } }

.ColumnWithLink-module__Icon___3NYSY {
  width: 18px; }
