.PageNotFound-module__Wrapper___wBNLB {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.PageNotFound-module__PageNotFoundContainer___2cw2G {
  position: relative;
  top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 520px;
  padding: 30px; }
  @media (min-width: 768px) {
    .PageNotFound-module__PageNotFoundContainer___2cw2G {
      top: 150px;
      flex-direction: row;
      min-height: 400px;
      height: 40vh; } }

.PageNotFound-module__ErrorCode___qBKkI {
  font-size: 4.8rem;
  line-height: 6rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  font-size: 8.0rem; }
  @media (min-width: 768px) {
    .PageNotFound-module__ErrorCode___qBKkI {
      padding-right: 30px; } }

.PageNotFound-module__PageNotFoundText___-j-UG {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2.4rem;
  margin-top: 50px;
  text-align: center; }
