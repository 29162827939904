.Column-module__Container___3QTQm {
  position: relative;
  display: flex;
  flex-direction: column; }

.Column-module__HeaderTop___2dcEu {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  width: 0;
  color: #191919;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .Column-module__HeaderTop___2dcEu {
      margin-top: 30px; } }

.Column-module__HeaderBottom___39J44 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #808080;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .Column-module__HeaderBottom___39J44 {
      margin-top: 30px; } }

.Column-module__Text___pah7X {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #191919;
  opacity: 0.9;
  margin-bottom: 70px;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .Column-module__Text___pah7X {
      margin-top: 30px; } }

.Column-module__Image___3VVkN {
  position: absolute;
  bottom: 0;
  justify-self: flex-end;
  align-self: flex-start;
  height: 30px; }
