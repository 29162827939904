.ThreeColumnsSection-module__Background___1C1gP {
  position: relative;
  overflow: hidden;
  background-color: #191919;
  padding-bottom: 50px;
  padding-top: 50px; }
  @media (min-width: 768px) {
    .ThreeColumnsSection-module__Background___1C1gP {
      padding-top: 100px;
      padding-bottom: 150px; } }

.ThreeColumnsSection-module__WhiteBackground___2iVKY {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
  padding-top: 50px; }

.ThreeColumnsSection-module__Layout___21iPV {
  position: relative; }

.ThreeColumnsSection-module__Header___5gK62, .ThreeColumnsSection-module__HeaderNotVisible___bSG58 {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  transition-duration: 0.5s; }
  @media (min-width: 500px) {
    .ThreeColumnsSection-module__Header___5gK62, .ThreeColumnsSection-module__HeaderNotVisible___bSG58 {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .ThreeColumnsSection-module__Header___5gK62, .ThreeColumnsSection-module__HeaderNotVisible___bSG58 {
      font-size: 4.8rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }

.ThreeColumnsSection-module__HeaderNotVisible___bSG58 {
  opacity: 0;
  transform: translateY(50px); }

.ThreeColumnsSection-module__Columns___3h_AK {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.ThreeColumnsSection-module__Column___1LEE1 {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  padding-top: 30px; }
  @media (min-width: 500px) {
    .ThreeColumnsSection-module__Column___1LEE1 {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .ThreeColumnsSection-module__Column___1LEE1 {
      width: calc(33.33333% - 26.66667px);
      float: left;
      margin-left: 20px; } }
  @media (min-width: 768px) and (min-width: 500px) {
    .ThreeColumnsSection-module__Column___1LEE1 {
      width: calc(33.33333% - 66.66667px);
      float: left;
      margin-left: 50px; } }

.ThreeColumnsSection-module__HorizontalLine___19CSa {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  margin-top: 50px;
  margin-bottom: 50px; }
  @media (min-width: 500px) {
    .ThreeColumnsSection-module__HorizontalLine___19CSa {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .ThreeColumnsSection-module__HorizontalLine___19CSa {
      margin-top: 100px; } }

.ThreeColumnsSection-module__ButtonContainer___67lr6 {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  display: flex;
  justify-content: center; }
  @media (min-width: 500px) {
    .ThreeColumnsSection-module__ButtonContainer___67lr6 {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }

.ThreeColumnsSection-module__Button___I859i {
  width: 100%; }
  @media (min-width: 500px) {
    .ThreeColumnsSection-module__Button___I859i {
      width: auto; } }

.ThreeColumnsSection-module__ButtonIcon___VyzO5 {
  height: 30px; }
