.SliderSection-module__Background___dsStA {
  position: relative;
  background-color: #191919;
  overflow: hidden; }

.SliderSection-module__Header___1iUU8, .SliderSection-module__HeaderNotVisible___jYsfh {
  position: absolute;
  z-index: 1;
  top: 50px;
  transition-duration: 0.6s;
  left: 0;
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  color: #FFFFFF;
  text-align: center;
  float: none; }
  @media (min-width: 768px) {
    .SliderSection-module__Header___1iUU8, .SliderSection-module__HeaderNotVisible___jYsfh {
      font-size: 4.8rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }
  @media (min-width: 1100px) {
    .SliderSection-module__Header___1iUU8, .SliderSection-module__HeaderNotVisible___jYsfh {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px;
      float: none;
      top: 150px; } }

.SliderSection-module__HeaderNotVisible___jYsfh {
  transform: translateY(50px);
  opacity: 0; }

.SliderSection-module__Slide___3415J {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px; }
  @media (min-width: 500px) {
    .SliderSection-module__Slide___3415J {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }

.SliderSection-module__Carousel___1dhhw {
  height: 100%;
  top: 0; }
  .SliderSection-module__Carousel___1dhhw .slick-track {
    display: block !important; }
  .SliderSection-module__Carousel___1dhhw .slick-slide {
    display: block !important; }

.SliderSection-module__Dots___3OQVy {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  position: absolute;
  z-index: 1;
  bottom: 30px; }
  @media (min-width: 500px) {
    .SliderSection-module__Dots___3OQVy {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .SliderSection-module__Dots___3OQVy {
      bottom: 100px; } }
  @media (min-width: 1100px) {
    .SliderSection-module__Dots___3OQVy {
      bottom: 150px; } }
