.VoiceRecorder-module__Container___28U5Y {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #191919;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%; }
  @media (min-width: 768px) {
    .VoiceRecorder-module__Container___28U5Y {
      flex-flow: row wrap;
      align-items: flex-start; } }
  @media (min-width: 1100px) {
    .VoiceRecorder-module__Container___28U5Y {
      align-items: flex-start;
      justify-content: flex-start; } }
  @media (min-width: 1920px) {
    .VoiceRecorder-module__Container___28U5Y {
      align-items: center; } }

.VoiceRecorder-module__AudioRecorder___2uUCf {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px; }
  @media (min-width: 500px) {
    .VoiceRecorder-module__AudioRecorder___2uUCf {
      width: auto; } }
  @media (min-width: 768px) {
    .VoiceRecorder-module__AudioRecorder___2uUCf {
      margin-bottom: 0; } }

.VoiceRecorder-module__AudioPlayer___30RYA {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition-duration: 0.2s; }
  @media (min-width: 768px) {
    .VoiceRecorder-module__AudioPlayer___30RYA {
      margin-left: 30px; } }

.VoiceRecorder-module__RecordIcon___2y6xh, .VoiceRecorder-module__StopRecordingIcon___1wnmF {
  display: inline-block;
  background-color: #FF0000;
  transition-duration: 0.4s;
  transition-timing-function: ease-out; }

.VoiceRecorder-module__RecordIcon___2y6xh {
  width: 20px;
  height: 20px;
  border-radius: 50%; }

.VoiceRecorder-module__StopRecordingIcon___1wnmF {
  width: 18px;
  height: 18px;
  margin-right: 2px; }

.VoiceRecorder-module__TextBeforeButton___3TWOq {
  margin-right: 20px; }

.VoiceRecorder-module__RecordButton___1FTOC {
  width: 100%; }

.VoiceRecorder-module__TextAfterButton___3iLcd {
  margin-left: 20px; }

.VoiceRecorder-module__AudioButton___2X8d9 {
  width: 16px;
  height: 16px;
  margin-left: 30px;
  background: none;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer; }

.VoiceRecorder-module__PlayButton___19YOG:before {
  content: "";
  width: 16px;
  height: 14px;
  border-left: 2px solid #808080;
  position: absolute;
  top: 1px;
  left: -1px; }

.VoiceRecorder-module__PlayButton___19YOG:after {
  content: "";
  position: absolute;
  top: 1px;
  left: -6px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg) skew(-15deg, -15deg);
  border-top: 2px solid #808080;
  border-right: 2px solid #808080;
  border-radius: 1px; }

.VoiceRecorder-module__PauseButton___3Rll1:before, .VoiceRecorder-module__PauseButton___3Rll1:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  height: 14px;
  width: 2px;
  background-color: #333; }

.VoiceRecorder-module__PauseButton___3Rll1:after {
  transform: translate(8px); }

.VoiceRecorder-module__DeleteButton___2E9BQ {
  margin-left: 20px; }
  .VoiceRecorder-module__DeleteButton___2E9BQ:before, .VoiceRecorder-module__DeleteButton___2E9BQ:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 16px;
    width: 2px;
    background-color: #333; }
  .VoiceRecorder-module__DeleteButton___2E9BQ:before {
    transform: rotate(45deg); }
  .VoiceRecorder-module__DeleteButton___2E9BQ:after {
    transform: rotate(-45deg); }

.VoiceRecorder-module__HiddenButton___3VqyV {
  display: none; }
