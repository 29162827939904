.HeaderSection-module__Container___1E_GZ {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  background-color: #191919;
  color: #FFFFFF;
  padding-bottom: 100px; }
  @media (min-width: 768px) {
    .HeaderSection-module__Container___1E_GZ {
      padding-top: 150px;
      padding-bottom: 100px;
      flex-direction: row; } }

.HeaderSection-module__ImagesContainer___2c6fc {
  position: relative;
  margin-top: 50px;
  width: 100%;
  height: 250px; }
  @media (min-width: 500px) {
    .HeaderSection-module__ImagesContainer___2c6fc {
      margin-top: 30px;
      height: 450px; } }
  @media (min-width: 768px) {
    .HeaderSection-module__ImagesContainer___2c6fc {
      position: absolute;
      overflow: hidden;
      margin-top: 0;
      top: 0;
      right: 0;
      height: 100%;
      width: 60%; } }

.HeaderSection-module__Image___3FYbG {
  object-fit: cover;
  object-position: top left;
  font-family: "object-fit:cover; object-position: top left;";
  width: 100%;
  height: 100%; }

.HeaderSection-module__TextContainer___1bhMO {
  position: relative;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px; }
  @media (min-width: 768px) {
    .HeaderSection-module__TextContainer___1bhMO {
      margin-top: 30px; } }
  @media (min-width: 500px) {
    .HeaderSection-module__TextContainer___1bhMO {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .HeaderSection-module__TextContainer___1bhMO {
      width: 50%;
      margin-top: 100px;
      width: calc(41.66667% - 70.83333px);
      float: left;
      margin-left: 50px; } }

.HeaderSection-module__HeaderTop___1rs-n {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  color: #05A4EF;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .HeaderSection-module__HeaderTop___1rs-n {
      margin-top: 30px;
      font-size: 5.6rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }

.HeaderSection-module__HeaderBottom___JdINd {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  color: #FFFFFF; }
  @media (min-width: 768px) {
    .HeaderSection-module__HeaderBottom___JdINd {
      font-size: 5.6rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }

.HeaderSection-module__Text___18f8P {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #FFFFFF;
  opacity: 0.9;
  margin-bottom: 30px;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .HeaderSection-module__Text___18f8P {
      margin-top: 30px;
      margin-bottom: 150px; } }

.HeaderSection-module__ArrowIcon___1ge7t {
  position: relative;
  width: 0;
  border-top: 18px solid #FFFFFF;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent; }
  .HeaderSection-module__ArrowIcon___1ge7t:after {
    content: '';
    position: absolute;
    top: -17px;
    left: -7px;
    width: 0;
    border-top: 15px solid #191919;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent; }

.HeaderSection-module__IconsContainer___1X7hV {
  position: relative;
  width: 150px;
  top: 60px;
  left: 20px; }
  @media (min-width: 500px) {
    .HeaderSection-module__IconsContainer___1X7hV {
      top: 150px;
      left: 60px; } }
  @media (min-width: 768px) {
    .HeaderSection-module__IconsContainer___1X7hV {
      top: 150px;
      left: unset;
      right: 45%; } }

.HeaderSection-module__SmallIcon___yPeNx {
  position: absolute;
  min-width: 30px;
  max-width: 60px; }

.HeaderSection-module__MediumIcon___15hhH {
  position: absolute;
  top: 150px;
  left: 50px;
  min-width: 40px;
  max-width: 100px; }
  @media (min-width: 768px) {
    .HeaderSection-module__MediumIcon___15hhH {
      top: 280px; } }

.HeaderSection-module__BigIcon1___2gJgX {
  position: absolute;
  top: 10px;
  left: 15%;
  min-width: 50px;
  max-width: 120px; }
  @media (min-width: 768px) {
    .HeaderSection-module__BigIcon1___2gJgX {
      top: 25px;
      left: 35px; } }

.HeaderSection-module__BigIcon2___3Ea0q {
  position: absolute;
  top: 180px;
  right: 60%;
  min-width: 50px;
  max-width: 120px; }
  @media (min-width: 768px) {
    .HeaderSection-module__BigIcon2___3Ea0q {
      top: 330px;
      right: 50%; } }

@media (min-width: 768px) {
  .HeaderSection-module__Button___3j0jl {
    margin-bottom: 120px; } }
