@keyframes HeroSection-module__fade-in-bottom___1z3YZ {
  0% {
    transform: translateY(50px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.HeroSection-module__Background___3q9vH {
  position: relative;
  background-color: #191919;
  color: #FFFFFF;
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (min-width: 768px) {
    .HeroSection-module__Background___3q9vH {
      padding-top: 150px;
      padding-bottom: 100px; } }

.HeroSection-module__Layout___2Mpt0 {
  position: relative; }

.HeroSection-module__Header___3Krhv {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  animation-name: HeroSection-module__fade-in-bottom___1z3YZ;
  animation-duration: 0.6s; }
  @media (min-width: 500px) {
    .HeroSection-module__Header___3Krhv {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .HeroSection-module__Header___3Krhv {
      font-size: 5.6rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500;
      padding-top: 100px;
      padding-bottom: 50px; } }

.HeroSection-module__Description___1Glbu {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  animation-name: HeroSection-module__fade-in-bottom___1z3YZ;
  animation-duration: 0.7s;
  text-align: center;
  margin-bottom: 30px;
  opacity: 0.9; }
  @media (min-width: 500px) {
    .HeroSection-module__Description___1Glbu {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .HeroSection-module__Description___1Glbu {
      width: calc(83.33333% - 91.66667px);
      float: left;
      margin-left: 50px;
      margin-left: calc(8.33333% - 54.16667px + 100px); } }
  @media (min-width: 1100px) {
    .HeroSection-module__Description___1Glbu {
      width: calc(50% - 75px);
      float: left;
      margin-left: 50px;
      margin-left: calc(25% - 62.5px + 100px); } }

.HeroSection-module__Image___17pPi {
  display: block;
  margin: 50px auto; }

.HeroSection-module__HorizontalLine___-gfEP {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  animation-name: HeroSection-module__fade-in-bottom___1z3YZ;
  animation-duration: 0.8s; }
  @media (min-width: 500px) {
    .HeroSection-module__HorizontalLine___-gfEP {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }

.HeroSection-module__SmallText___2Macv {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  text-align: center;
  padding-top: 50px;
  animation-name: HeroSection-module__fade-in-bottom___1z3YZ;
  animation-duration: 1s; }
  @media (min-width: 500px) {
    .HeroSection-module__SmallText___2Macv {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }

.HeroSection-module__Br___o-VHf {
  display: none; }
  @media (min-width: 768px) {
    .HeroSection-module__Br___o-VHf {
      display: block; } }
