.Timer-module__Time___14vP3 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 15px; }
  @media (min-width: 1100px) {
    .Timer-module__Time___14vP3 {
      padding: 8px 20px; } }
