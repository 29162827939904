.ScrollSliderSection-module__Container___2YhrT {
  overflow: hidden; }

.ScrollSliderSection-module__Background___2LV0B {
  position: relative;
  padding: 50px 0;
  background-color: #05A4EF; }
  @media (min-width: 768px) {
    .ScrollSliderSection-module__Background___2LV0B {
      padding-top: 0;
      margin-top: -90px;
      display: flex;
      top: 0;
      width: 100%;
      height: 110vh;
      opacity: 1;
      transition: position, 0.1s, ease-out; } }

.ScrollSliderSection-module__BackgroundImage___3wD9Y {
  object-fit: cover;
  object-position: 0 0;
  font-family: "object-fit:cover; object-position: 0 0;";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  filter: brightness(80%);
  pointer-events: none; }
  @media (min-width: 768px) {
    .ScrollSliderSection-module__BackgroundImage___3wD9Y {
      height: 150%; } }

.ScrollSliderSection-module__Slides___DefZZ {
  display: block;
  white-space: nowrap;
  width: calc(100% - 100px);
  float: left;
  margin-left: 50px; }
  .ScrollSliderSection-module__Slides___DefZZ > * {
    margin-right: 30px; }

.ScrollSliderSection-module__MobileSlidesContainer___1tiw- {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px; }
