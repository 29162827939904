.ColumnWithFullScreenImage-module__Container___hmElI {
  position: relative;
  display: flex;
  flex-direction: column; }

.ColumnWithFullScreenImage-module__HeaderTop___34CZL {
  color: #05A4EF;
  margin-top: 30px;
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  font-size: 30px; }

.ColumnWithFullScreenImage-module__HeaderBottom___2LL5K {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  font-size: 30px; }

.ColumnWithFullScreenImage-module__Text___OHOMd {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-top: 30px;
  opacity: 0.8; }

.ColumnWithFullScreenImage-module__SmallText___3z6P5 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  opacity: 0.9;
  margin-bottom: 70px;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .ColumnWithFullScreenImage-module__SmallText___3z6P5 {
      margin-top: 30px;
      margin-bottom: 150px; } }

.ColumnWithFullScreenImage-module__AssetContainer___1aiwx {
  position: relative;
  overflow: hidden; }

.ColumnWithFullScreenImage-module__FullScreenIconContainer___3Yh5T {
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: #191919;
  transition-duration: 0.3s;
  cursor: pointer; }
  .ColumnWithFullScreenImage-module__FullScreenIconContainer___3Yh5T:hover {
    filter: brightness(75%); }
  .ColumnWithFullScreenImage-module__FullScreenIconContainer___3Yh5T:hover + .ColumnWithFullScreenImage-module__Asset___1-m-z {
    filter: brightness(95%);
    transform: scale(1.08); }
  @media (min-width: 768px) {
    .ColumnWithFullScreenImage-module__FullScreenIconContainer___3Yh5T {
      bottom: auto;
      top: 0; } }

.ColumnWithFullScreenImage-module__FullScreenIcon___6HYn4 {
  height: 20px;
  width: 20px; }

.ColumnWithFullScreenImage-module__Asset___1-m-z {
  height: auto;
  width: 100%;
  position: relative;
  z-index: 1; }

.ColumnWithFullScreenImage-module__Notched___7A4Y2 {
  width: 100%;
  position: absolute;
  bottom: 8px;
  top: 8px; }
  .ColumnWithFullScreenImage-module__Notched___7A4Y2:after, .ColumnWithFullScreenImage-module__Notched___7A4Y2:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #FFFFFF;
    border-right: 15px solid #FFFFFF;
    z-index: 1; }
  .ColumnWithFullScreenImage-module__Notched___7A4Y2:after {
    bottom: auto;
    top: 100%;
    border-bottom: none;
    border-top: 15px solid transparent; }
