.VideoSection-module__Container___1ZxQr {
  position: relative;
  background-color: #05A4EF;
  overflow: hidden; }

.VideoSection-module__Video___id4BF {
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit:cover; object-position: 50% 50%;";
  width: 100%;
  height: 100%;
  transform: scale(1.015); }

.VideoSection-module__Button___3MeDC {
  position: absolute;
  transition-delay: 0.3s;
  transition-duration: 0.3s;
  opacity: 1;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%); }
  @media (min-width: 500px) {
    .VideoSection-module__Button___3MeDC {
      margin-left: 0;
      width: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); } }

.VideoSection-module__ButtonIcon___x2wlA {
  height: 18px; }

.VideoSection-module__FullScreenContainer___10kc6 {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0; }

.VideoSection-module__FullScreenBackground___codJ7 {
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.95);
  cursor: pointer; }

.VideoSection-module__FullScreenAssetWrapper___3EjwS {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100vw; }
  @media (min-width: 1366px) {
    .VideoSection-module__FullScreenAssetWrapper___3EjwS {
      width: 75vw; } }

.VideoSection-module__FullScreenAsset___29tPa {
  padding-top: 56.25%; }

.VideoSection-module__Iframe___3pPwe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
