html {
  overflow-x: hidden;
  font-size: 8.5px;
  line-height: 8.5px;
  color: #191919; }
  @media (min-width: 500px) {
    html {
      font-size: calc(8.5px + 1.5 * ((100vw - 500px) / 1420));
      line-height: calc(8.5px + 1.5 * ((100vw - 500px) / 1420)); } }
  @media (min-width: 1100px) {
    html {
      font-size: 8.5px;
      line-height: 8.5px; } }
  @media (min-width: 1366px) {
    html {
      font-size: 10px;
      line-height: 10px; } }

* {
  margin: 0;
  padding: 0;
  min-height: 0;
  min-width: 0;
  box-sizing: border-box; }

.slick-list {
  overflow: visible; }

.slick-track {
  display: flex; }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center; }

.custom-slick-dots li, .custom-slick-dots-black li, .custom-slick-dots-white li {
  display: inline-block;
  margin-top: 20px;
  margin-left: 10px; }
  @media (min-width: 500px) {
    .custom-slick-dots li:first-of-type, .custom-slick-dots-black li:first-of-type, .custom-slick-dots-white li:first-of-type {
      margin-left: 40px; } }
  .custom-slick-dots li button, .custom-slick-dots-black li button, .custom-slick-dots-white li button {
    border: none;
    width: 30px;
    height: 30px;
    line-height: 0;
    font-size: 0;
    outline: none;
    background-color: transparent;
    cursor: pointer; }
  .custom-slick-dots li button:after, .custom-slick-dots-black li button:after, .custom-slick-dots-white li button:after {
    display: inline-block;
    position: relative;
    top: -3px;
    bottom: 8px;
    border: 2px solid #191919;
    border-radius: 15px;
    width: 0;
    height: 0;
    content: ''; }
  .custom-slick-dots li.slick-active button:after, .custom-slick-dots-black li.slick-active button:after, .custom-slick-dots-white li.slick-active button:after {
    width: 6px;
    height: 6px;
    top: 0; }
  .custom-slick-dots li button:hover:after, .custom-slick-dots-black li button:hover:after, .custom-slick-dots-white li button:hover:after {
    border-color: #808080; }

.custom-slick-dots-white li button:after {
  border-color: #FFFFFF; }
