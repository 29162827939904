@media (min-width: 1100px) {
  .ParallaxVideo-module__Background___5GRjY {
    margin: 150px 0; } }

.ParallaxVideo-module__Video___1C5NT {
  width: 100%;
  height: auto;
  min-height: 58vh;
  object-fit: cover;
  object-position: 0% 0%;
  font-family: "object-fit:cover; object-position: 0% 0%;"; }
  @media (min-width: 1100px) {
    .ParallaxVideo-module__Video___1C5NT {
      min-height: initial; } }
