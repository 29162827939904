.SmallColumnsSection-module__Background___3TwU_ {
  overflow: hidden;
  background-color: #FFFFFF;
  padding-bottom: 50px; }
  @media (min-width: 768px) {
    .SmallColumnsSection-module__Background___3TwU_ {
      padding-top: 150px;
      padding-bottom: 150px; } }

.SmallColumnsSection-module__HeaderContainer___3otuV {
  display: flex;
  flex-direction: column; }

.SmallColumnsSection-module__Header___1CXxU, .SmallColumnsSection-module__HeaderNotVisible___1BxlJ {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  padding: 30px 0;
  color: #191919;
  text-align: center;
  transition-duration: 0.4s; }
  @media (min-width: 500px) {
    .SmallColumnsSection-module__Header___1CXxU, .SmallColumnsSection-module__HeaderNotVisible___1BxlJ {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .SmallColumnsSection-module__Header___1CXxU, .SmallColumnsSection-module__HeaderNotVisible___1BxlJ {
      font-size: 4.8rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }

.SmallColumnsSection-module__HeaderNotVisible___1BxlJ {
  opacity: 0;
  transform: translateY(50px); }

.SmallColumnsSection-module__Text___3SKcn, .SmallColumnsSection-module__TextNotVisible___2Kxk9 {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #191919;
  text-align: center;
  margin-bottom: 50px;
  transition-duration: 0.6s;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px; }
  @media (min-width: 768px) {
    .SmallColumnsSection-module__Text___3SKcn, .SmallColumnsSection-module__TextNotVisible___2Kxk9 {
      width: calc(66.66667% - 83.33333px);
      float: left;
      margin-left: 50px;
      margin-left: calc(16.66667% - 58.33333px + 100px);
      margin-bottom: 100px; } }
  @media (min-width: 1100px) {
    .SmallColumnsSection-module__Text___3SKcn, .SmallColumnsSection-module__TextNotVisible___2Kxk9 {
      width: calc(50% - 75px);
      float: left;
      margin-left: 50px;
      margin-left: calc(25% - 62.5px + 100px); } }

.SmallColumnsSection-module__TextNotVisible___2Kxk9 {
  opacity: 0;
  transform: translateY(50px); }

.SmallColumnsSection-module__Rows___3iaHA {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 500px) {
    .SmallColumnsSection-module__Rows___3iaHA {
      justify-content: center; } }

.SmallColumnsSection-module__Row___2JuH2 {
  display: flex; }
  @media (min-width: 500px) {
    .SmallColumnsSection-module__Row___2JuH2 {
      width: auto; } }

.SmallColumnsSection-module__Column___2CLns {
  width: calc(8.33333% - 21.66667px);
  float: left;
  margin-left: 20px;
  padding-right: 29vw;
  padding-bottom: 50px;
  width: 30vw; }
  @media (min-width: 500px) {
    .SmallColumnsSection-module__Column___2CLns {
      width: calc(8.33333% - 54.16667px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 500px) {
    .SmallColumnsSection-module__Column___2CLns {
      width: 70px;
      padding-right: 100px;
      padding-bottom: 50px; } }
