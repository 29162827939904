.PartnersSection-module__Background___2i_UN {
  background-color: #FFFFFF; }

.PartnersSection-module__ImagesContainer___24S34 {
  overflow: hidden;
  justify-content: center; }
  @media (min-width: 768px) {
    .PartnersSection-module__ImagesContainer___24S34 {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px;
      display: flex;
      justify-content: center; } }

.PartnersSection-module__PartnerImageContainer___3IjuW {
  outline: none;
  padding: 30px 0;
  float: none; }
  @media (min-width: 768px) {
    .PartnersSection-module__PartnerImageContainer___3IjuW {
      width: auto;
      margin: auto; } }
  @media (min-width: 1100px) {
    .PartnersSection-module__PartnerImageContainer___3IjuW {
      padding: 50px 100px; } }

.PartnersSection-module__Slider___i33d_ .slick-track {
  justify-content: center; }

.PartnersSection-module__Slider___i33d_ .slick-slide {
  justify-content: center; }

.PartnersSection-module__Image___f1y9k {
  width: calc(50vw - 40px); }
  @media (min-width: 500px) {
    .PartnersSection-module__Image___f1y9k {
      width: calc(30vw - 40px); } }
  @media (min-width: 768px) {
    .PartnersSection-module__Image___f1y9k {
      min-width: 150px;
      width: auto; } }
