.CheckBox-module__Container___3acR8 {
  display: flex;
  padding-bottom: 40px;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s; }
  .CheckBox-module__Container___3acR8:hover {
    opacity: 0.8; }

.CheckBox-module__CheckBox___1mxpT {
  display: block;
  margin-right: 20px;
  margin-top: 5px;
  border: 1px solid silver;
  min-width: 40px;
  height: 40px; }

.CheckBox-module__NativeCheckBox___3WWPx {
  opacity: 0;
  cursor: pointer; }

.CheckBox-module__Label___1GDp5 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #191919;
  cursor: pointer; }

.CheckBox-module__CheckMark___oZ7vx {
  position: relative;
  top: 9px;
  left: 15px;
  display: inline-block; }
  .CheckBox-module__CheckMark___oZ7vx:after {
    content: '';
    display: block;
    width: 6px;
    height: 14px;
    border: solid #191919;
    border-width: 0 2px 2px 0;
    transform: rotate(46deg); }
