.SliderSectionSlide-module__Container___Kw0rH {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin-left: 0; }
  @media (min-width: 768px) {
    .SliderSectionSlide-module__Container___Kw0rH {
      display: block;
      position: relative; } }

.SliderSectionSlide-module__ImagesContainer___3VWbd {
  position: relative;
  margin-top: 50px;
  width: 100%;
  height: 300px; }
  @media (min-width: 500px) {
    .SliderSectionSlide-module__ImagesContainer___3VWbd {
      margin-top: 30px;
      height: 450px; } }
  @media (min-width: 768px) {
    .SliderSectionSlide-module__ImagesContainer___3VWbd {
      position: absolute;
      overflow: hidden;
      margin-top: 0;
      top: 0;
      left: 50%;
      height: 100%;
      width: 50%; } }

.SliderSectionSlide-module__Image___3HIMH {
  position: absolute;
  object-fit: cover;
  object-position: top left;
  font-family: "object-fit:cover; object-position: top left;";
  width: 100%;
  height: 100%; }

.SliderSectionSlide-module__Grid___X2z8m {
  position: relative;
  display: flex;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .SliderSectionSlide-module__Grid___X2z8m {
      height: 600px; } }
  @media (min-width: 1100px) {
    .SliderSectionSlide-module__Grid___X2z8m {
      height: 800px; } }

.SliderSectionSlide-module__TextContainer___3VKyp {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  margin-top: 30px; }
  @media (min-width: 500px) {
    .SliderSectionSlide-module__TextContainer___3VKyp {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .SliderSectionSlide-module__TextContainer___3VKyp {
      width: calc(41.66667% - 70.83333px);
      float: left;
      margin-left: 50px;
      margin-top: 150px; } }
  @media (min-width: 1100px) {
    .SliderSectionSlide-module__TextContainer___3VKyp {
      margin-top: 300px; } }

.SliderSectionSlide-module__BlueHeadingText___1TheK {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #05A4EF; }

.SliderSectionSlide-module__HeaderTop___jnqex {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .SliderSectionSlide-module__HeaderTop___jnqex {
      margin-top: 30px; } }

.SliderSectionSlide-module__HeaderBottom___3gVas {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 100;
  color: #FFFFFF; }

.SliderSectionSlide-module__Text___2vEqj {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #808080;
  opacity: 0.9;
  margin-bottom: 70px;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .SliderSectionSlide-module__Text___2vEqj {
      margin-top: 30px; } }

.SliderSectionSlide-module__Clickable___3evJM {
  cursor: pointer; }
