.RichTextWithTitles-module__Container___2Im3N {
  width: calc(100% - 100px);
  float: left;
  margin-left: 50px;
  padding-top: 100px;
  padding-bottom: 20px; }
  @media (min-width: 768px) {
    .RichTextWithTitles-module__Container___2Im3N {
      padding-top: 150px; } }
  @media (min-width: 1100px) {
    .RichTextWithTitles-module__Container___2Im3N {
      width: calc(83.33333% - 91.66667px);
      float: left;
      margin-left: 50px;
      margin-left: calc(8.33333% - 54.16667px + 100px);
      padding-top: 200px;
      padding-bottom: 50px; } }

.RichTextWithTitles-module__Title___9Z61q {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 100;
  margin-bottom: 20px; }
  @media (min-width: 1100px) {
    .RichTextWithTitles-module__Title___9Z61q {
      font-size: 4.8rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }

.RichTextWithTitles-module__Content___1RQo_ {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  margin-top: 30px; }
  @media (min-width: 1100px) {
    .RichTextWithTitles-module__Content___1RQo_ {
      margin-top: 50px; } }
  .RichTextWithTitles-module__Content___1RQo_ ol {
    padding-left: 20px; }

.RichTextWithTitles-module__BackHomeButton___3EdK8 {
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  display: block;
  width: fit-content;
  margin-top: 20px;
  color: #191919;
  text-decoration: none; }
  .RichTextWithTitles-module__BackHomeButton___3EdK8:hover {
    opacity: 0.8; }
  @media (min-width: 1100px) {
    .RichTextWithTitles-module__BackHomeButton___3EdK8 {
      margin-top: 50px; } }
