.AudioAnalyser-module__SoundBar___NQG4O {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px; }
  @media (min-width: 768px) {
    .AudioAnalyser-module__SoundBar___NQG4O {
      width: 180px; } }
  @media (min-width: 1100px) {
    .AudioAnalyser-module__SoundBar___NQG4O {
      width: 185px; } }
  .AudioAnalyser-module__SoundBar___NQG4O svg rect {
    transition: all 0.1s ease-out; }
