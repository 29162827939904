.FullScreenColumn-module__FullScreenContainer___1mbO4 {
  display: flex;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0; }

.FullScreenColumn-module__FullScreenBackground___1fcoI {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(25, 25, 25, 0.95);
  cursor: pointer; }

.FullScreenColumn-module__FullScreenAsset___12JMt {
  align-self: center;
  position: relative;
  z-index: 1;
  width: 100vw; }
  @media (min-width: 1366px) {
    .FullScreenColumn-module__FullScreenAsset___12JMt {
      height: 75vh; } }

.FullScreenColumn-module__CloseIconContainer___1MvXP {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s; }
  .FullScreenColumn-module__CloseIconContainer___1MvXP:hover {
    opacity: 0.8; }

.FullScreenColumn-module__CloseIcon___1-Vz2 {
  width: 20px;
  height: 20px; }
