.Button-module__Button___2YI99 {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px silver;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #191919;
  transition-duration: 0.2s;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s; }
  .Button-module__Button___2YI99:hover {
    opacity: 0.8; }

.Button-module__ButtonChildren___2tq8t {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px; }
  @media (min-width: 1100px) {
    .Button-module__ButtonChildren___2tq8t {
      padding-left: 50px;
      padding-right: 50px; } }

.Button-module__IconContainer___DoxXE {
  border-left: 1px solid #e6e6e6;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 60px;
  min-width: 60px;
  display: flex; }

.Button-module__Outline___dlJUL {
  background-color: transparent;
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s; }
  .Button-module__Outline___dlJUL:hover {
    opacity: 0.6; }
  .Button-module__Outline___dlJUL .Button-module__IconContainer___DoxXE {
    border-left: 1px solid rgba(255, 255, 255, 0.05); }

.Button-module__Blue___6aZyY {
  background-color: #05A4EF;
  border: none;
  color: #FFFFFF; }
  .Button-module__Blue___6aZyY .Button-module__ButtonChildren___2tq8t {
    padding-left: 70px;
    padding-right: 70px; }

.Button-module__Disabled___2qYO4 {
  background-color: silver;
  cursor: default; }
  .Button-module__Disabled___2qYO4:hover {
    opacity: 1; }
  .Button-module__Disabled___2qYO4 .Button-module__IconContainer___DoxXE {
    border-left: 1px solid #808080; }
