.MobileNavigiation-module__MenuBurgerContainer___rcgoA {
  position: absolute;
  right: 100px; }

.MobileNavigiation-module__CollapedMenu___Y2Phm {
  width: 100%;
  background-color: #191919;
  position: fixed;
  left: 0;
  overflow: auto;
  display: none;
  flex-direction: column;
  justify-content: center; }
  .MobileNavigiation-module__CollapedMenu___Y2Phm.MobileNavigiation-module__MobileNavShow___3yOWH {
    position: fixed;
    height: 100%;
    bottom: 0;
    display: flex; }
  .MobileNavigiation-module__CollapedMenu___Y2Phm.MobileNavigiation-module__MobileNavHide___2DYxO {
    position: fixed;
    top: 100%; }

.MobileNavigiation-module__OpenedMenuContainer___17IGj {
  display: flex;
  flex-direction: column;
  align-items: center; }

.MobileNavigiation-module__BigLink___iDIoF, .MobileNavigiation-module__BigIconLink___3bM0t {
  margin: 30px 0;
  height: 30px; }
  @media (min-width: 768px) {
    .MobileNavigiation-module__BigLink___iDIoF, .MobileNavigiation-module__BigIconLink___3bM0t {
      margin-top: 45px; } }

.MobileNavigiation-module__MobileIcon___uYhwi {
  height: 30px;
  width: 30px; }

.MobileNavigiation-module__MobileLink___1tnmk {
  padding: 10px; }

.MobileNavigiation-module__MobileNavLink___31Uye {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 100; }

.MobileNavigiation-module__MobileNavLink___31Uye:hover:after {
  border-bottom: none; }
