.FullScreenSlider-module__FullScreenContainer___3_EAh {
  display: flex;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0; }

.FullScreenSlider-module__FullScreenBackground___-M48M {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(25, 25, 25, 0.95);
  cursor: pointer; }

.FullScreenSlider-module__FullScreenSlider___zF7sl {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center; }
  .FullScreenSlider-module__FullScreenSlider___zF7sl .slick-track {
    display: flex !important;
    justify-content: center; }
  .FullScreenSlider-module__FullScreenSlider___zF7sl .slick-slide {
    display: flex !important;
    justify-content: center; }

.FullScreenSlider-module__FullScreenAsset___2KJn9 {
  object-fit: contain;
  object-position: 0 0;
  font-family: "object-fit:contain; object-position: 0 0;";
  position: relative;
  z-index: 1;
  width: 100vw; }
  @media (min-width: 1366px) {
    .FullScreenSlider-module__FullScreenAsset___2KJn9 {
      width: auto;
      height: 75vh; } }

.FullScreenSlider-module__LeftSideArrowContainer___4p06t, .FullScreenSlider-module__RightSideArrowContainer___zrCAd, .FullScreenSlider-module__CloseIconContainer___2ErJq {
  background: none;
  outline: none;
  border: none;
  user-select: none; }

.FullScreenSlider-module__LeftSideArrowContainer___4p06t, .FullScreenSlider-module__RightSideArrowContainer___zrCAd {
  position: fixed;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 75vh;
  color: #FFFFFF;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s; }
  .FullScreenSlider-module__LeftSideArrowContainer___4p06t:hover, .FullScreenSlider-module__RightSideArrowContainer___zrCAd:hover {
    opacity: 0.5; }
  @media (min-width: 1366px) {
    .FullScreenSlider-module__LeftSideArrowContainer___4p06t, .FullScreenSlider-module__RightSideArrowContainer___zrCAd {
      width: 300px; } }
  @media (min-width: 2560px) {
    .FullScreenSlider-module__LeftSideArrowContainer___4p06t, .FullScreenSlider-module__RightSideArrowContainer___zrCAd {
      width: 450px; } }

.FullScreenSlider-module__LeftSideArrowContainer___4p06t {
  left: 0; }

.FullScreenSlider-module__RightSideArrowContainer___zrCAd {
  right: 0; }

.FullScreenSlider-module__ArrowLeft___3vA_i, .FullScreenSlider-module__ArrowRight___1Rsxv {
  width: 30px;
  height: 30px; }

.FullScreenSlider-module__ArrowRight___1Rsxv {
  transform: rotate(180deg); }

.FullScreenSlider-module__CloseIconContainer___2ErJq {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s; }
  .FullScreenSlider-module__CloseIconContainer___2ErJq:hover {
    opacity: 0.8; }

.FullScreenSlider-module__CloseIcon___3z1eV {
  width: 20px;
  height: 20px; }
