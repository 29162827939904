.FourColumnsSection-module__Background___TObwW {
  background-color: #FFFFFF;
  padding-bottom: 30px;
  padding-top: 30px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .FourColumnsSection-module__Background___TObwW {
      padding-top: 100px;
      padding-bottom: 150px; } }

.FourColumnsSection-module__Header___2_HVZ, .FourColumnsSection-module__HeaderNotVisible___2hBip {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  color: #191919;
  text-align: center;
  float: none;
  transition-duration: 0.5s; }
  @media (min-width: 768px) {
    .FourColumnsSection-module__Header___2_HVZ, .FourColumnsSection-module__HeaderNotVisible___2hBip {
      font-size: 4.8rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500;
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px;
      float: none; } }
  @media (min-width: 1100px) {
    .FourColumnsSection-module__Header___2_HVZ, .FourColumnsSection-module__HeaderNotVisible___2hBip {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }

.FourColumnsSection-module__HeaderNotVisible___2hBip {
  opacity: 0;
  transform: translateY(50px); }

.FourColumnsSection-module__Columns___1oeIZ {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.FourColumnsSection-module__Column4___7B8mh, .FourColumnsSection-module__Column3___i44mA, .FourColumnsSection-module__Column2___1C1gI, .FourColumnsSection-module__Column1___3pMGB {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px; }
  @media (min-width: 500px) {
    .FourColumnsSection-module__Column4___7B8mh, .FourColumnsSection-module__Column3___i44mA, .FourColumnsSection-module__Column2___1C1gI, .FourColumnsSection-module__Column1___3pMGB {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 1100px) {
    .FourColumnsSection-module__Column4___7B8mh, .FourColumnsSection-module__Column3___i44mA, .FourColumnsSection-module__Column2___1C1gI, .FourColumnsSection-module__Column1___3pMGB {
      width: calc(25% - 25px);
      float: left;
      margin-left: 20px;
      padding-top: 30px;
      margin-bottom: 0; } }
  @media (min-width: 1100px) and (min-width: 500px) {
    .FourColumnsSection-module__Column4___7B8mh, .FourColumnsSection-module__Column3___i44mA, .FourColumnsSection-module__Column2___1C1gI, .FourColumnsSection-module__Column1___3pMGB {
      width: calc(25% - 62.5px);
      float: left;
      margin-left: 50px; } }

@media (min-width: 1100px) {
  .FourColumnsSection-module__Column3___i44mA {
    width: calc(33.33333% - 26.66667px);
    float: left;
    margin-left: 20px;
    padding-top: 30px;
    margin-bottom: 0; } }
  @media (min-width: 1100px) and (min-width: 500px) {
    .FourColumnsSection-module__Column3___i44mA {
      width: calc(33.33333% - 66.66667px);
      float: left;
      margin-left: 50px; } }

@media (min-width: 1100px) {
  .FourColumnsSection-module__Column2___1C1gI {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
    padding-top: 30px;
    margin-bottom: 0; } }
  @media (min-width: 1100px) and (min-width: 500px) {
    .FourColumnsSection-module__Column2___1C1gI {
      width: calc(50% - 75px);
      float: left;
      margin-left: 50px; } }

@media (min-width: 1100px) {
  .FourColumnsSection-module__Column1___3pMGB {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding-top: 30px;
    margin-bottom: 0; } }
  @media (min-width: 1100px) and (min-width: 500px) {
    .FourColumnsSection-module__Column1___3pMGB {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }

.FourColumnsSection-module__Carousel___3wD37 {
  width: 100%; }
