.SmallColumn-module__Image___1lHM1 {
  height: 15vw; }
  @media (min-width: 500px) {
    .SmallColumn-module__Image___1lHM1 {
      height: 60px; } }

.SmallColumn-module__HorizontalLine___YvdwM {
  margin: 30px 0;
  border-color: #e6e6e6;
  width: 19vw; }
  @media (min-width: 500px) {
    .SmallColumn-module__HorizontalLine___YvdwM {
      width: 70px; } }

.SmallColumn-module__Text___2iX4N {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #191919;
  opacity: 0.9; }
