.Navigation-module__Navigation___3k1wr {
  position: fixed;
  top: 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #191919;
  width: 100%;
  z-index: 999;
  height: 50px; }
  @media (min-width: 768px) {
    .Navigation-module__Navigation___3k1wr {
      height: 90px; } }

.Navigation-module__Logo___391ch, .Navigation-module__BigLogo___1R_gE {
  position: relative;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s;
  height: 37px;
  transition-duration: 0.3s;
  margin-left: 20px; }
  .Navigation-module__Logo___391ch:hover, .Navigation-module__BigLogo___1R_gE:hover {
    opacity: 0.8; }
  @media (min-width: 1100px) {
    .Navigation-module__Logo___391ch, .Navigation-module__BigLogo___1R_gE {
      margin-left: 50px;
      height: 60px; } }
  @media (min-width: 1366px) {
    .Navigation-module__Logo___391ch, .Navigation-module__BigLogo___1R_gE {
      height: 60px;
      margin-left: 100px; } }

@media (min-width: 1100px) {
  .Navigation-module__BigLogo___1R_gE {
    margin-top: 30px; } }

.Navigation-module__Logo___391ch {
  height: 36px; }
  @media (min-width: 1100px) {
    .Navigation-module__Logo___391ch {
      height: 57px; } }
  @media (min-width: 1366px) {
    .Navigation-module__Logo___391ch {
      height: 57px; } }

.Navigation-module__LinksContainer___1-zbu {
  position: relative;
  display: inline-flex;
  justify-content: flex-end; }

.Navigation-module__BigLink___3ujQK, .Navigation-module__BigIconLink___NRXd0 {
  margin-top: 15px;
  height: 20px; }
  @media (min-width: 768px) {
    .Navigation-module__BigLink___3ujQK, .Navigation-module__BigIconLink___NRXd0 {
      margin-top: 45px; } }

.Navigation-module__Link___18XvW, .Navigation-module__IconLink___5Majx {
  margin-top: 15px;
  height: 20px; }
  @media (min-width: 768px) {
    .Navigation-module__Link___18XvW, .Navigation-module__IconLink___5Majx {
      margin-top: 35px;
      transition-duration: 0.3s; } }

.Navigation-module__Link___18XvW {
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s;
  margin-right: 20px; }
  .Navigation-module__Link___18XvW:hover {
    opacity: 0.8; }
  @media (min-width: 1366px) {
    .Navigation-module__Link___18XvW {
      margin-right: 50px; } }

.Navigation-module__BigLink___3ujQK {
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s;
  margin-right: 20px; }
  .Navigation-module__BigLink___3ujQK:hover {
    opacity: 0.8; }
  @media (min-width: 1366px) {
    .Navigation-module__BigLink___3ujQK {
      margin-right: 50px; } }

.Navigation-module__IconLink___5Majx, .Navigation-module__BigIconLink___NRXd0 {
  cursor: pointer;
  opacity: 0.5;
  transition-duration: 0.2s;
  margin-right: 20px; }
  .Navigation-module__IconLink___5Majx:hover, .Navigation-module__BigIconLink___NRXd0:hover {
    opacity: 0.3; }

@media (min-width: 768px) {
  .Navigation-module__ContactLinkContainer___NWBJp {
    margin-left: 30px; } }

@media (min-width: 1366px) {
  .Navigation-module__ContactLinkContainer___NWBJp {
    margin-left: 50px; } }

.Navigation-module__BigContactLink___2AmEX, .Navigation-module__ContactLink___DslU1 {
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  transition-duration: 0.2s;
  text-align: center;
  cursor: pointer;
  background-color: #05A4EF;
  color: #FFFFFF;
  width: 100px;
  height: 50px;
  padding-top: 20px; }
  .Navigation-module__BigContactLink___2AmEX:hover, .Navigation-module__ContactLink___DslU1:hover {
    opacity: 0.8; }
  @media (min-width: 768px) {
    .Navigation-module__BigContactLink___2AmEX, .Navigation-module__ContactLink___DslU1 {
      width: 150px;
      height: 90px;
      padding-top: 45px; } }
  @media (min-width: 1366px) {
    .Navigation-module__BigContactLink___2AmEX, .Navigation-module__ContactLink___DslU1 {
      width: 250px; } }

@media (min-width: 768px) {
  .Navigation-module__BigContactLink___2AmEX {
    padding-top: 45px; } }

@media (min-width: 768px) {
  .Navigation-module__ContactLink___DslU1 {
    padding-top: 35px; } }
