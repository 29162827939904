.ContactForm-module__Container___1xf67 {
  padding-top: 30px;
  padding-bottom: 50px; }
  @media (min-width: 768px) {
    .ContactForm-module__Container___1xf67 {
      padding-top: 100px;
      padding-bottom: 100px; } }

.ContactForm-module__Header___2mAXr, .ContactForm-module__HeaderNotVisible___31F3F {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  color: #191919;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  text-align: center;
  padding: 30px 0;
  transition-duration: 0.4s; }
  @media (min-width: 500px) {
    .ContactForm-module__Header___2mAXr, .ContactForm-module__HeaderNotVisible___31F3F {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .ContactForm-module__Header___2mAXr, .ContactForm-module__HeaderNotVisible___31F3F {
      font-size: 4.8rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }

.ContactForm-module__HeaderNotVisible___31F3F {
  opacity: 0;
  transform: translateY(50px); }

.ContactForm-module__UnderHeaderText___W5-yn, .ContactForm-module__UnderHeaderTextNotVisible___1Idzj {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #191919;
  text-align: center;
  padding-bottom: 30px;
  transition-duration: 0.6s; }
  @media (min-width: 768px) {
    .ContactForm-module__UnderHeaderText___W5-yn, .ContactForm-module__UnderHeaderTextNotVisible___1Idzj {
      padding: 20px 0 50px; } }

.ContactForm-module__UnderHeaderTextNotVisible___1Idzj {
  opacity: 0;
  transform: translateY(50px); }

.ContactForm-module__Input___e9dr1, .ContactForm-module__CheckBoxes___3ZcoM, .ContactForm-module__VoiceRecorderContainer___3rBDa, .ContactForm-module__UnderButtonText___ySOd0, .ContactForm-module__UnderHeaderText___W5-yn, .ContactForm-module__UnderHeaderTextNotVisible___1Idzj {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px; }
  @media (min-width: 500px) {
    .ContactForm-module__Input___e9dr1, .ContactForm-module__CheckBoxes___3ZcoM, .ContactForm-module__VoiceRecorderContainer___3rBDa, .ContactForm-module__UnderButtonText___ySOd0, .ContactForm-module__UnderHeaderText___W5-yn, .ContactForm-module__UnderHeaderTextNotVisible___1Idzj {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .ContactForm-module__Input___e9dr1, .ContactForm-module__CheckBoxes___3ZcoM, .ContactForm-module__VoiceRecorderContainer___3rBDa, .ContactForm-module__UnderButtonText___ySOd0, .ContactForm-module__UnderHeaderText___W5-yn, .ContactForm-module__UnderHeaderTextNotVisible___1Idzj {
      width: calc(66.66667% - 83.33333px);
      float: left;
      margin-left: 50px;
      margin-left: calc(16.66667% - 58.33333px + 100px); } }
  @media (min-width: 1100px) {
    .ContactForm-module__Input___e9dr1, .ContactForm-module__CheckBoxes___3ZcoM, .ContactForm-module__VoiceRecorderContainer___3rBDa, .ContactForm-module__UnderButtonText___ySOd0, .ContactForm-module__UnderHeaderText___W5-yn, .ContactForm-module__UnderHeaderTextNotVisible___1Idzj {
      width: calc(50% - 75px);
      float: left;
      margin-left: 50px;
      margin-left: calc(25% - 62.5px + 100px); } }

.ContactForm-module__VoiceRecorderContainer___3rBDa {
  margin-top: 20px; }
  @media (min-width: 768px) {
    .ContactForm-module__VoiceRecorderContainer___3rBDa {
      width: calc(83.33333% - 91.66667px);
      float: left;
      margin-left: 50px;
      margin-left: calc(16.66667% - 58.33333px + 100px); } }
  @media (min-width: 1100px) {
    .ContactForm-module__VoiceRecorderContainer___3rBDa {
      width: calc(58.33333% - 79.16667px);
      float: left;
      margin-left: 50px;
      margin-left: calc(25% - 62.5px + 100px); } }

.ContactForm-module__CheckBoxes___3ZcoM {
  margin-top: 10px;
  opacity: 0;
  height: 0;
  transform: translateY(-100%);
  overflow: hidden;
  transition-duration: 0.5s; }

.ContactForm-module__CheckBoxes___3ZcoM.ContactForm-module__Visible___20BoD {
  transform: translateY(0);
  opacity: 1;
  height: auto; }

.ContactForm-module__CaptchaContainer___3zzaO {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
  @media (min-width: 500px) {
    .ContactForm-module__CaptchaContainer___3zzaO {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .ContactForm-module__CaptchaContainer___3zzaO {
      width: calc(83.33333% - 91.66667px);
      float: left;
      margin-left: 50px;
      margin-left: calc(16.66667% - 58.33333px + 100px); } }
  @media (min-width: 1100px) {
    .ContactForm-module__CaptchaContainer___3zzaO {
      width: calc(58.33333% - 79.16667px);
      float: left;
      margin-left: 50px;
      margin-left: calc(25% - 62.5px + 100px); } }

.ContactForm-module__CaptchaError___2idS2 {
  color: red;
  font-size: medium;
  margin-top: 6.66667px; }

.ContactForm-module__ButtonContainer___1tVi7 {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  display: flex;
  justify-content: center; }
  @media (min-width: 500px) {
    .ContactForm-module__ButtonContainer___1tVi7 {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }

.ContactForm-module__Button___3Mr-J {
  width: 100%;
  text-align: center;
  margin-top: 20px; }
  @media (min-width: 500px) {
    .ContactForm-module__Button___3Mr-J {
      width: auto; } }

.ContactForm-module__UnderButtonText___ySOd0 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  text-align: center;
  padding-top: 30px; }
