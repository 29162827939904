.Footer-module__Footer___1Ec02 {
  background-color: #191919;
  padding-top: 30px;
  padding-bottom: 100px; }
  @media (min-width: 768px) {
    .Footer-module__Footer___1Ec02 {
      padding-top: 100px;
      padding-bottom: 150px; } }

.Footer-module__Container___x4eZh {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px; }
  @media (min-width: 500px) {
    .Footer-module__Container___x4eZh {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px;
      flex-direction: row;
      justify-content: center; } }

.Footer-module__TextContainer___2RULD {
  justify-content: space-between;
  margin-top: 20px; }
  @media (min-width: 500px) {
    .Footer-module__TextContainer___2RULD {
      justify-content: center;
      margin-top: 30px; } }

.Footer-module__SocialContainer___rlC4m {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px; }
  @media (min-width: 500px) {
    .Footer-module__SocialContainer___rlC4m {
      justify-content: center;
      margin-top: 30px; } }

.Footer-module__Link___1BoGB {
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #FFFFFF;
  margin-top: 3.33333px;
  margin-right: 20px; }
  .Footer-module__Link___1BoGB:hover {
    opacity: 0.8; }
  @media (min-width: 1100px) {
    .Footer-module__Link___1BoGB {
      margin-top: 0;
      margin-right: 50px; } }

.Footer-module__FooterText___1Gu_E {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #808080; }

.Footer-module__IconLink___2H-KM {
  cursor: pointer;
  opacity: 0.5;
  transition-duration: 0.2s;
  margin-left: 20px; }
  .Footer-module__IconLink___2H-KM:hover {
    opacity: 0.3; }
