.ScrollSliderSectionSlide-module__Container___3c-1D {
  display: inline-block; }

.ScrollSliderSectionSlide-module__SlideContainer___2ldh5 {
  position: relative;
  overflow: hidden; }

.ScrollSliderSectionSlide-module__Asset___2ZjZQ {
  transition-duration: 0.5s;
  transform: scale(1.02);
  height: auto;
  width: 100%; }
  @media (min-width: 768px) {
    .ScrollSliderSectionSlide-module__Asset___2ZjZQ {
      width: auto;
      height: 300px; } }
  @media (min-width: 1920px) {
    .ScrollSliderSectionSlide-module__Asset___2ZjZQ {
      height: 15.5vw; } }
  @media (min-width: 2560px) {
    .ScrollSliderSectionSlide-module__Asset___2ZjZQ {
      height: 450px; } }

.ScrollSliderSectionSlide-module__FullScreenIconContainer___30tWr {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: #191919;
  transition-duration: 0.3s;
  cursor: pointer; }
  .ScrollSliderSectionSlide-module__FullScreenIconContainer___30tWr:hover {
    filter: brightness(75%); }
  .ScrollSliderSectionSlide-module__FullScreenIconContainer___30tWr:hover + .ScrollSliderSectionSlide-module__Asset___2ZjZQ {
    filter: brightness(95%);
    transform: scale(1.08); }
  @media (min-width: 768px) {
    .ScrollSliderSectionSlide-module__FullScreenIconContainer___30tWr {
      bottom: auto;
      top: 0; } }

.ScrollSliderSectionSlide-module__FullScreenIcon___3XTR5 {
  height: 20px;
  width: 20px; }

.ScrollSliderSectionSlide-module__Text___2wWxN {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #191919;
  margin-top: 20px;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .ScrollSliderSectionSlide-module__Text___2wWxN {
      margin-bottom: 0; } }
