.MenuBurger-module__MenuBurger___2D3bJ {
  position: relative;
  color: #FFFFFF;
  margin: 30px;
  display: block;
  float: left;
  width: 30px;
  height: 25px;
  z-index: 2;
  cursor: pointer;
  border-top: 2px solid;
  border-bottom: 2px solid;
  font-size: 0;
  transition: all 0.2s cubic-bezier(0.35, 0.85, 0.5, 0.95) !important; }
  .MenuBurger-module__MenuBurger___2D3bJ:before, .MenuBurger-module__MenuBurger___2D3bJ:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: currentColor;
    transform: translate(-50%, 0);
    transition: transform 0.2s cubic-bezier(0.35, 0.85, 0.5, 0.95) !important; }
  .MenuBurger-module__MenuBurger___2D3bJ.MenuBurger-module__BurgerOpen___1Hl5H {
    border-color: transparent; }
    .MenuBurger-module__MenuBurger___2D3bJ.MenuBurger-module__BurgerOpen___1Hl5H:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .MenuBurger-module__MenuBurger___2D3bJ.MenuBurger-module__BurgerOpen___1Hl5H:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
