.Dots-module__Dots___2lz7d {
  display: flex; }
  .Dots-module__Dots___2lz7d li {
    position: relative;
    left: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer; }
    .Dots-module__Dots___2lz7d li .Dots-module__Button___38-kk {
      display: inline-block;
      background: transparent;
      bottom: 8px;
      border: 2px solid #191919;
      border-radius: 15px;
      width: 0;
      height: 0;
      content: '';
      outline: none; }
    .Dots-module__Dots___2lz7d li.Dots-module__Active___1iUuv .Dots-module__Button___38-kk {
      width: 10px;
      height: 10px; }
  .Dots-module__Dots___2lz7d li:hover .Dots-module__Button___38-kk {
    border-color: #808080; }

.Dots-module__White___2DMwJ li .Dots-module__Button___38-kk {
  border-color: #FFFFFF; }
