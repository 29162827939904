.TwoColumnsSection-module__Container___1OzPK {
  border: none;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column-reverse; }
  @media (min-width: 768px) {
    .TwoColumnsSection-module__Container___1OzPK {
      flex-direction: unset; } }

@media (min-width: 768px) {
  .TwoColumnsSection-module__TextLeftContainer___1hPW8 {
    left: 55%; } }

.TwoColumnsSection-module__TextContainer___1gLhW {
  margin-top: 30px;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px; }
  @media (min-width: 500px) {
    .TwoColumnsSection-module__TextContainer___1gLhW {
      width: calc(100% - 100px);
      float: left;
      margin-left: 50px; } }
  @media (min-width: 768px) {
    .TwoColumnsSection-module__TextContainer___1gLhW {
      position: relative;
      width: calc(41.66667% - 70.83333px);
      float: left;
      margin-left: 50px;
      margin-top: 100px; } }

.TwoColumnsSection-module__HeaderTop___9DfrU {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  color: #05A4EF;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .TwoColumnsSection-module__HeaderTop___9DfrU {
      margin-top: 30px;
      font-size: 4.8rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }

.TwoColumnsSection-module__HeaderBottom___s2JLl {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500; }
  @media (min-width: 768px) {
    .TwoColumnsSection-module__HeaderBottom___s2JLl {
      font-size: 4.8rem;
      line-height: 6rem;
      font-family: 'halyard-display', sans-serif;
      font-weight: 500; } }

.TwoColumnsSection-module__Text___3bHX7 {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500;
  font-weight: 300;
  margin-top: 30px; }

.TwoColumnsSection-module__TextColor___Gw7m7 {
  color: #B5B5B5; }

.TwoColumnsSection-module__SmallText___YE4kc {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  opacity: 0.9;
  margin-bottom: 70px;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .TwoColumnsSection-module__SmallText___YE4kc {
      margin-top: 30px;
      margin-bottom: 150px; } }

.TwoColumnsSection-module__ImageContainer___3YYXQ {
  width: 100%; }
  @media (min-width: 768px) {
    .TwoColumnsSection-module__ImageContainer___3YYXQ {
      overflow: hidden;
      position: absolute;
      bottom: 0;
      top: 0;
      height: 100%;
      width: 50%; } }

.TwoColumnsSection-module__ImageLeftContainer___1n1Ez {
  left: 50%; }

.TwoColumnsSection-module__SmallImage___3_tbz {
  display: block;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 20px; }

.TwoColumnsSection-module__ImageContain___MawRq {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
  font-family: "object-fit:contain; object-position: 50% 50%;"; }

.TwoColumnsSection-module__Image___35Y83 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
  font-family: "object-fit:cover; object-position: top left;"; }
