.Input-module__Container___3JKPv {
  margin: 25px 0;
  cursor: text; }

.Input-module__Label___17mxT {
  position: absolute;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #191919;
  transform: translateY(-14px);
  transition-duration: 0.2s; }

.Input-module__Label___17mxT.Input-module__Active___2dw_f {
  transform-origin: top left;
  transform: translateY(-24px) scale(0.8);
  color: #808080; }

.Input-module__Input___1p9w8 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #191919;
  min-width: 100%;
  max-width: 100%;
  border: none;
  border-bottom: 2px solid #191919;
  outline: none;
  resize: none;
  overflow: hidden;
  box-shadow: none; }

.Input-module__Invalid___3Rv2s .Input-module__Label___17mxT, .Input-module__Invalid___3Rv2s .Input-module__Label___17mxT.Input-module__Active___2dw_f {
  color: #FF0000; }

.Input-module__Invalid___3Rv2s .Input-module__Input___1p9w8 {
  color: #FF0000;
  border-color: #FF0000; }
