.Column-module__HeaderContainer___3Zyx5 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #FFFFFF;
  padding: 30px 0; }

.Column-module__Header___3mAWR {
  position: relative;
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 100;
  z-index: 1; }

.Column-module__HeaderBold___2wn_U {
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-family: 'halyard-display', sans-serif;
  font-weight: 500; }

.Column-module__Image___TVEo1 {
  position: absolute;
  right: 0;
  z-index: 0;
  height: 70px; }
  @media (min-width: 1100px) {
    .Column-module__Image___TVEo1 {
      margin-right: 30px;
      height: 100px; } }
  @media (min-width: 1920px) {
    .Column-module__Image___TVEo1 {
      margin-right: 50px; } }

.Column-module__Text___27BSt {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #FFFFFF;
  opacity: 0.9; }
